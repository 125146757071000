import { useEffect } from "react";
import { useStore } from "../../context/createFastContext";
import SelectWithDropdown from "../common/SelectWithDropdown/SelectWithDropdown";
import { _earningDropdownOptions, _earning_Deduction_DropdownOptions } from "./PayrollCommon";
import InputFields from "../common/Input/InputFields";
import { formattedAmount } from "../../utils/common";
import AddSvg from "../../media/AddSvg";
import MinusSvg from "../../media/MinusSvg";
import { PayrollStatus } from "../../enum/PayrollStatus";
import { convert_Date } from "../../utils/helper";
import CheckSvg from "../../media/CheckSvg";
import { DeductionType } from "../../enum/DeductionType";
import { StatutoryComponentType } from "../../enum/StatutoryComponentType";

const PreviewModalComponent = ({ renderPreviewModal }) => {
  const [payrollState, setStore] = useStore(s => s.payrollState);

  const payslip = payrollState.previewRowObj;
  const payslip_statutory_comps = payslip?.payslipStatutoryComponents;
  const payslip_earnings = payslip?.payslipComponents;
  const payslip_deductions = payslip?.payslipDeductions;

  const fixedPayslipEarnings = payslip_earnings?.filter(component => {
    return (
      component.isFixedPay === true
    );
  });

  const editablePayslipEarnings = payslip_earnings?.filter(component => {
    return (
      component.isFixedPay === false
    );
  });

  
  const fixedPayslipDeductions = payslip_deductions?.filter(component => {
    return (
      component.deductionType === DeductionType.LOP
    );
  });

  const editablePayslipDeductions = payslip_deductions?.filter(component => {
    return (
      component.deductionType !== DeductionType.LOP
    );
  });

  const tenantEarnings = payrollState?.responseEarnings;

  const filteredTenantEarnings = tenantEarnings?.filter((tenantEarning, i) => {
    return !editablePayslipEarnings || !editablePayslipEarnings.map(ve => ve.salaryComponentId).includes(tenantEarning.id);
  });

  const tenantDeductions = payrollState?.responseDeductions;

  const filteredTenantDeductions = tenantDeductions?.filter((tenantDeduction, i) => {
    return (
      !editablePayslipDeductions || !editablePayslipDeductions.map(ve => ve.tenantDeductionId).includes(tenantDeduction.id)
    );
  });

  useEffect(() => {
    setTimeout(() => {
      setStore({
        payrollState: {
          ...payrollState,
          previewRowObj: {
            ...payrollState.previewRowObj,
            earning_dropdownOptions: filteredTenantEarnings,
            deduction_dropdownOptions: filteredTenantDeductions,
          },
          selectedEarningOptions: editablePayslipEarnings,
          selectedDeductionOptions: editablePayslipDeductions,
        },
      });
    }, 200);
  }, []);

  const handleOptionSelect = (_, option) => {
    // Adding the selected option to the state
    const updatedSelectedOptions = [
      ...payrollState.selectedEarningOptions,
      { id: 0,
        salaryComponentName: option.label, 
        salaryComponentId: option.value,
        amount: 0, },
    ];

    const updatedAvailableOptions = payrollState.previewRowObj.earning_dropdownOptions.filter(
      opt => opt.id !== option.value
    );

    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          earning_dropdownOptions: updatedAvailableOptions,
        },
        selectedEarningOptions: updatedSelectedOptions,
      },
    });
  };

  const handleDeductionOptionSelect = (_, option) => {
    // Adding the selected option to the state
    const updatedSelectedOptions = [
      ...payrollState.selectedDeductionOptions,
      { id: 0,
        tenantDeductionName: option.label, 
        tenantDeductionId: option.value,
        amount: 0, },
    ];

    const updatedDeductionAvailableOptions =
      payrollState.previewRowObj.deduction_dropdownOptions.filter(opt => opt.id !== option.value);

    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          deduction_dropdownOptions: updatedDeductionAvailableOptions,
        },
        selectedDeductionOptions: updatedSelectedOptions,
      },
    });
  };

  const handleDeletePsDeduction = (tenantDeductionId, name) => {
    const updatedSelectedOptions = payrollState.selectedDeductionOptions.filter(psDeduction => psDeduction.tenantDeductionId !== tenantDeductionId);
    const updatedAvailableOptions = [
      ...payrollState.previewRowObj.deduction_dropdownOptions,
      { id: tenantDeductionId, name: name},
    ];

    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          deduction_dropdownOptions: updatedAvailableOptions,
        },
        selectedDeductionOptions: updatedSelectedOptions,
      },
    });
  }

  const handleDeletePsEarning = (salaryComponentId, name) => {

    const updatedSelectedOptions = payrollState.selectedEarningOptions.filter(psEarning => psEarning.salaryComponentId !== salaryComponentId);

    const updatedAvailableOptions = [
      ...payrollState.previewRowObj.earning_dropdownOptions,
      { id: salaryComponentId, name: name },
    ];

    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          earning_dropdownOptions: updatedAvailableOptions,
        },
        selectedEarningOptions: updatedSelectedOptions,
      },
    });
  };

  useEffect(() => {
    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          lopDays: payrollState.previewRowObj.lopDays,
        },
      },
    });
  }, []);

  const handleLOPDays = e => {
    const { name, value, id } = e.target;

    // Updating the lop days value in state
    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          lopDays: value,
        },
      },
    });
  };

  const handleLOPDaysBlur = e => {
    const { value } = e.target;

    // Updating the lop days value in state
    setStore({
      payrollState: {
        ...payrollState,
        previewRowObj: {
          ...payrollState.previewRowObj,
          lopDays: value.trim() === "" ? 0 : value,
        },
      },
    });
  };

  const handlePsDeductionInput = e => {
    const { name, value, id } = e.target;
    const updatedSelectedPsDeductions = payrollState.selectedDeductionOptions.map((psDed, i) => {
      if(psDed.tenantDeductionId === Number(id)) {
        return {...psDed, amount: value};
      }
      return psDed;
    });
    setStore({
      payrollState: {
        ...payrollState,
        selectedDeductionOptions: updatedSelectedPsDeductions
      },
    });
  }

  const handlePsEarningInput = e => {
    const { name, value, id } = e.target;
    const updatedSelectedPsEarnings = payrollState.selectedEarningOptions.map((psEarn, i) => {
      if(psEarn.salaryComponentId === Number(id)) {
        return {...psEarn, amount: value};
      }
      return psEarn;
    });
    setStore({
      payrollState: {
        ...payrollState,
        selectedEarningOptions: updatedSelectedPsEarnings
      },
    });
  };

  return (
    <div className="flex flex-col justify-center">
      {/* Employee Name etc. */}
      <div className="border border-1 rounded-t-lg mt-6 mx-6 px-6 py-4 flex justify-between bg-blue-50 dark:bg-blue-400">
        <div>
          <div className="text-lg font-semibold text-blue-600">
            {payslip?.firstName + " " + payslip?.lastName}
          </div>
          <div className="text-gray-600 font-semibold">Emp. ID: {payslip?.employeeId}</div>
        </div>
        <div>
          <div>Net Pay</div>
          <div className="text-gray-600 font-semibold">
            {formattedAmount(payslip?.totalNetPayable, "en-IN", "INR", 2, 2)}
          </div>
        </div>
      </div>

      {/* Details */}
      <div className="h-[60vh] pl-6 pr-[0.9rem] overflow-y-scroll">
        <div className="border border-1 bg-gray-50 dark:bg-gray-600">
          {payslip?.paymentStatus && (
            <div className="tracking-[0.5px] flex gap-2 items-center py-4 px-6 font-semibold text-green-600 bg-green-100 dark:bg-green-600">
              <span>
                <CheckSvg />
              </span>
              Paid on{" "}
              <span className="font-bold">
                {`${convert_Date(
                  false,
                  false,
                  payslip.paymentDate,
                  "en-GB",
                  "2-digit",
                  "2-digit",
                  "numeric"
                )}`}
              </span>
            </div>
          )}
          <div className="flex justify-between mt-4 mb-3 px-6">
            <div className="text-gray-600 dark:text-gray-700 font-semibold">Payable Days</div>
            <div className="text-gray-600 dark:text-gray-700 font-semibold">
              {payrollState?.res?.payrollDetails?.baseDays}
            </div>
          </div>

          <div className="flex justify-between px-6 mb-4">
            <span className="pt-2 text-gray-600 dark:text-gray-700 font-semibold">LOP Days</span>
            <span
              className={`${
                payrollState.status === PayrollStatus.Draft
                  ? "flex gap-2"
                  : "text-gray-600 dark:text-gray-700 font-semibold"
              }`}>
              {payrollState.status === PayrollStatus.Draft ? (
                <InputFields
                  InputField={{
                    id: 999,
                    type: "number",
                    name: "lopDays",
                    placeholder: "Enter or Edit LOP Days",
                  }}
                  inputClassName="text-right"
                  value={payrollState.previewRowObj.lopDays}
                  onChange={handleLOPDays}
                  disabled={payrollState.status !== PayrollStatus.Draft}
                  otherProps={{ onBlur: handleLOPDaysBlur }}
                />
              ) : (
                payrollState.previewRowObj.lopDays
              )}
            </span>
          </div>

          {(payrollState.status === PayrollStatus.Draft ||
            payrollState?.previewRowObj.lopDays > 0) && (
            <>
              <hr className="h-px mb-1 bg-gray-200 border-dashed dark:bg-gray-700"></hr>
              <div className="flex justify-between my-3 px-6">
                <div className="text-md text-[1.2rem] text-gray-800 dark:text-gray-700 font-semibold">
                  Actual Payable Days
                </div>
                <div className="text-md text-[1.2rem] text-gray-800 dark:text-gray-700 font-semibold">
                  {isNaN(payslip?.paidDays) === false &&
                    payrollState?.res?.payrollDetails?.baseDays -
                      payrollState?.previewRowObj.lopDays}
                </div>
              </div>
            </>
          )}
          <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />

          <div className="flex bg-blue-100 items-center justify-between py-4 px-6">
            <div className="text-green-500 font-semibold tracking-[0.5px]">(+) Earnings</div>
            <div className="text-md text-gray-600 dark:text-gray-700 font-semibold">Amount</div>
          </div>

          <hr className="h-px mb-2 bg-gray-200 border-0 dark:bg-gray-700" />

          {/* // Earnings */}
          <div className="flex flex-col gap-3 py-4 px-6">
            {payslip_earnings &&
              fixedPayslipEarnings?.map((component, i) => {
                // const componentDetails = component.componentDetails;
                return (
                  <div key={i} className="flex justify-between">
                    <div className="text-gray-600 dark:text-gray-700 font-[500]">
                      {component.salaryComponentName}
                    </div>
                    <div className="text-gray-600 dark:text-gray-700 font-[500]">
                      {formattedAmount(component.amount, "en-IN", "INR", 2, 2)}
                    </div>
                  </div>
                );
              })}

            <div className="flex flex-col gap-3">
              {payrollState?.selectedEarningOptions?.map(psEditableEarning => (
                <div key={psEditableEarning.salaryComponentId} className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-700 font-[500]">{psEditableEarning.salaryComponentName}</span>
                  <span
                    className={`${
                      payrollState.status === PayrollStatus.Draft
                        ? "flex gap-2"
                        : "text-gray-600 dark:text-gray-700 font-semibold"
                    }`}>
                    {payrollState.status === PayrollStatus.Draft ? (
                      <>
                        <InputFields
                          InputField={{
                            id: psEditableEarning.salaryComponentId,
                            type: "number",
                            name: psEditableEarning.salaryComponentName,
                          }}
                          inputClassName="text-right"
                          value={psEditableEarning.amount}
                          // value={payrollState.inputValues[option.id]}
                          onChange={handlePsEarningInput}
                        />
                        <button
                          className="text-lg font-medium text-center text-red-500"
                          onClick={() => handleDeletePsEarning(psEditableEarning.salaryComponentId, psEditableEarning.salaryComponentName)}>
                          x
                        </button>
                      </>
                    ) : (
                      formattedAmount(psEditableEarning.amount, "en-IN", "INR", 2, 2)
                    )}
                  </span>
                </div>
              ))}
            </div>

            {payrollState.status === PayrollStatus.Draft &&
              payrollState?.previewRowObj?.earning_dropdownOptions?.length > 0 && (
                <label className="flex items-center gap-1 text-blue-600">
                  <AddSvg size={24} />
                  <div htmlFor="earning" className="tc-dropdown tc-dropdown-svg">
                    <SelectWithDropdown
                      inline={true}
                      roundedDot={false}
                      className="-pl-4 w-[120px] font-semibold tracking-[0.5px]"
                      size="sm"
                      dotClassName=""
                      id="earning"
                      options={_earning_Deduction_DropdownOptions(
                        payrollState?.previewRowObj?.earning_dropdownOptions
                      )}
                      defaultValue="Add Earning"
                      handleChange={handleOptionSelect}
                      backgroundColor="bg-gray-100"
                      isDefaultValue={true}
                    />
                  </div>
                </label>
              )}
          </div>

          <hr className="h-px mt-2 bg-gray-200 border-0 dark:bg-gray-700" />

          <div className="flex justify-between bg-blue-100 py-4 px-6">
            <div className="text-red-500 font-semibold tracking-[0.5px]">(-) Deductions</div>
            <div className="text-md text-gray-600 dark:text-gray-700 font-semibold">Amount</div>
          </div>

          <hr className="h-px mb-2 bg-gray-200 border-0 dark:bg-gray-700" />

          {/* // Deductions */}
          <div className="flex flex-col gap-3 py-4 px-6">
            {payslip_deductions &&
              fixedPayslipDeductions.map((component, i) => {
                return (
                  <div
                    key={i}
                    className="flex justify-between text-gray-600 dark:text-gray-700 font-[500]">
                    <div className="text-gray-600 dark:text-gray-700 font-[500]">
                      {component.tenantDeductionName}
                    </div>
                    <div className="text-gray-600 dark:text-gray-700 font-[500]">
                      {formattedAmount(component.amount, "en-IN", "INR", 2, 2)}
                    </div>
                  </div>
                );
              })}

            <div className="flex flex-col gap-3">
              {payrollState.selectedDeductionOptions.map(psEditableDeduction => (
                <div key={psEditableDeduction.tenantDeductionId} className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-700 font-[500]">{psEditableDeduction.tenantDeductionName}</span>
                  <span
                    className={`${
                      payrollState.status === PayrollStatus.Draft
                        ? "flex gap-2"
                        : "text-gray-600 dark:text-gray-700 font-semibold"
                    }`}>
                    {payrollState.status === PayrollStatus.Draft ? (
                      <>
                        <InputFields
                          InputField={{
                            id: psEditableDeduction.tenantDeductionId,
                            type: "number",
                            name: psEditableDeduction.tenantDeductionName,
                          }}
                          inputClassName="text-right"
                          labelClassName="text-gray-600 dark:text-gray-700 font-[500]"
                          value={psEditableDeduction.amount}
                          // value={payrollState.inputValues[option.id]}
                          onChange={handlePsDeductionInput}
                        />
                        <button
                          className="text-lg font-medium text-center text-red-500"
                          onClick={() => handleDeletePsDeduction(psEditableDeduction.tenantDeductionId, psEditableDeduction.tenantDeductionName)}>
                          x
                        </button>
                      </>
                    ) : (
                      formattedAmount(psEditableDeduction.amount, "en-IN", "INR", 2, 2)
                    )}
                  </span>
                </div>
              ))}
            </div>

            {payrollState.status === PayrollStatus.Draft &&
              payrollState?.previewRowObj?.deduction_dropdownOptions?.length > 0 && (
                <label htmlFor="deduction" className="flex items-center gap-1 text-blue-600">
                  <MinusSvg size={20} />
                  <div className="tc-dropdown tc-dropdown-svg">
                    <SelectWithDropdown
                      inline={true}
                      roundedDot={false}
                      className="-pl-4 w-[200px] text-blue-600 font-[500]"
                      size="sm"
                      id="deduction"
                      dotClassName=""
                      options={_earning_Deduction_DropdownOptions(
                        payrollState?.previewRowObj?.deduction_dropdownOptions
                      )}
                      defaultValue="Add Deduction"
                      handleChange={handleDeductionOptionSelect}
                      backgroundColor="bg-gray-100"
                      isDefaultValue={true}
                    />
                  </div>
                </label>
              )}
          </div>

          <div className="flex justify-between bg-blue-100 py-4 px-6">
            <div className="text-green-500 font-semibold tracking-[0.5px]">Statutory Components</div>
            <div className="text-md text-gray-600 dark:text-gray-700 font-semibold">Amount</div>
          </div>

          <hr className="h-px mb-2 bg-gray-200 border-0 dark:bg-gray-700" />
          <div className="flex flex-col gap-3 py-4 px-6">
            {payslip_statutory_comps &&
              payslip_statutory_comps.map((component, i) => {
                return (
                  <div
                    key={i}>
                    {(component.statutoryComponentType === StatutoryComponentType.EPF || component.statutoryComponentType === StatutoryComponentType.ESI) ? (
                      <div>
                        <div className="flex justify-between text-gray-600 dark:text-gray-700 font-[500]">
                          <div className="text-gray-600 dark:text-gray-700 font-[500]">
                            {component.statutoryComponentName + " Employee Contribution"}
                          </div>
                          <div className="text-gray-600 dark:text-gray-700 font-[500]">
                            {formattedAmount(component.employeesContribution, "en-IN", "INR", 2, 2)}
                          </div>
                        </div>
                        <div className="flex justify-between text-gray-600 dark:text-gray-700 font-[500]">
                          <div className="text-gray-600 dark:text-gray-700 font-[500]">
                            {component.statutoryComponentName + " Employer Contribution"}
                          </div>
                          <div className="text-gray-600 dark:text-gray-700 font-[500]">
                            {formattedAmount(component.employersContribution, "en-IN", "INR", 2, 2)}
                          </div>
                        </div>
                      </div>  
                    ) : (
                      <div className="flex justify-between text-gray-600 dark:text-gray-700 font-[500]">
                        <div className="text-gray-600 dark:text-gray-700 font-[500]">
                          {component.statutoryComponentName}
                        </div>
                        <div className="text-gray-600 dark:text-gray-700 font-[500]">
                          {formattedAmount(component.employeesContribution, "en-IN", "INR", 2, 2)}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        {/* // Net Pay */}
        <div className="border rounded-b-lg bg-gray-200 flex justify-between mb-2 px-6 py-4">
          <div className="text-gray-700 dark:text-gray-800 text-[1.2rem] font-[500]">Net Pay</div>
          <div className="text-gray-700 dark:text-gray-800 text-[1.2rem] font-[500]">
            {formattedAmount(payslip?.totalNetPayable, "en-IN", "INR", 2, 2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewModalComponent;
