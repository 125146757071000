import MandatorySign from "../../common/Input/MandatorySign";
import { salaryComponentsTableHeading } from "../helper";
import { CalculationType, calculationTypeLabels } from "../../../enum/CalculationType";
import Table from "../../common/Table/Table";
import { AiFillPlusCircle } from "react-icons/ai";
import TableHeader from "../../common/Table/TableHeader";
import TableRows from "../../common/Table/TableRows";
import TableData from "../../common/Table/TableData";
import styles from "../EmployeeManagement.module.css";
import { useEffect, useState } from "react";
import InputFields from "../../common/Input/InputFields";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { StatutoryComponentType } from "../../../enum/StatutoryComponentType";
import { setAnnualCTC, setEarningAssociationValues, setRegimeType, setDeductionAssociationValues, getSalaryStrucuture } from "../../../store/slices/salaryStructureSlice";
import { EarningCategory } from "../../../enum/EarningCategory";
import { RegimeTypeLabels } from "../../../enum/RegimeType";
import { Dropdown } from "primereact/dropdown";
import { numberInWords } from "../../../utils/common";
import { MdOutlineEdit } from "react-icons/md";

/**
 * Renders user salary structure.
 *
 * @return {JSX.Element} - The rendered salary structure.
 */
const SalaryStructure = ({}) => {

  const dispatch = useAppDispatch();
  const salaryStructure = useAppSelector(state => state.salaryStructure)
  const umState = useAppSelector(state => state.userManagement);
  const isActive = umState.selectedUserModalItem === "salaryComponents";
  const user = useAppSelector(state => state.userManagement.user);

  const regimeTypeOptions = Object.entries(RegimeTypeLabels).map(([value, label]) => ({name: label, value, itemClassName:"border border-red-700"})) 

  useEffect(() => {
    dispatch(getSalaryStrucuture(umState.isEditingUser, true));
  }, []);

  // Function to POST request for salary structure when losing focus on input fields
  const onBlurGetSalaryStructure = async() => {
    await dispatch(getSalaryStrucuture(umState.isEditingUser, false))
  }

  // Drop down options for pre tax deductions 
  const getPreTaxDeductionOptions = () => {
    //Exclude already available rows from deduction association values in UI
    const ptds = salaryStructure.tenantPreTaxDeductions.filter(tenantPtd =>
      !salaryStructure.deductionAssociationValues.some(dav =>
        dav.tenantDeductionId === tenantPtd.id
      )).map(option => {
        return {
          value: option.id,
          label: option.name,
          dotClassName: "",
          itemClassName: "w-[170px]",
        }
      })

    return ptds;
  }

  const handleDeleteSelectedPreTaxDeduction = (event, tenantDeductionId, name) => {
    event.preventDefault();
    const updatedDeductionAssociationValues = salaryStructure.deductionAssociationValues.filter(dav => dav.tenantDeductionId !== tenantDeductionId);
    dispatch(setDeductionAssociationValues(updatedDeductionAssociationValues));
  }

  const handlePreTaxDeductionOptionSelect = (_, option) => {
    
    const tenantDeductionId = +option.value;
    
    let existingAssociationId = 0;

    if(umState.isEditingUser) {
      existingAssociationId = user.deductionAssociations.find(ded => ded.tenantDeductionId == tenantDeductionId)?.id ?? 0;
    }

    const deductionAssociationValues = [
      ...salaryStructure.deductionAssociationValues,
      {
        id: existingAssociationId,
        tenantDeductionId: tenantDeductionId,
        tenantDeductionName: option.label,
        calculationValue: 0,
        annualAmount: 0
      }
    ]

    dispatch(setDeductionAssociationValues(deductionAssociationValues));
  }

  const handleDeductionValueChange = (tenantDeductionId, event) => {
    const calculationValue = +event.target.value;
    const updatedDeductionAssociationValues = salaryStructure.deductionAssociationValues.map(dav => {
      if(dav.tenantDeductionId === tenantDeductionId) {
        return {
          ...dav,
          calculationValue
        }
      }
      return dav;
    })

    dispatch(setDeductionAssociationValues(updatedDeductionAssociationValues));
  }

  const onChangeAnnualCTC = (e) => {
    dispatch(setAnnualCTC(+e.target.value))
  }

  //Function for formatting earning values.
  const formatNumber = value => {
    if (value != null) {
      return parseFloat(value).toFixed(2);
    }
    return "";
  };


  // on cha
  const handleEarningValueChange = (tenantEarningId, event) => {
    const value = +event.target.value
    const updatedEarningAssociationValues = salaryStructure.earningAssociationValues.map(eav => {
      if(eav.tenantEarningId === tenantEarningId) {
        const monthlyAmount = eav.calculationType === CalculationType.FlatAmount ? value : eav.monthlyAmount
        return {
          ...eav,
          monthlyAmount,
          calculationValue: value,
        }
      }
      return eav;
    })
    dispatch(setEarningAssociationValues(updatedEarningAssociationValues));
  }

  const onChangeRegimeType = (e) => {
    const regimeType = e.value;
    dispatch(setRegimeType(regimeType));
  }

  return (
    <>
      {isActive && (
        <div className=" mb-4 sm:mb-8  min-h-[20rem]">
          {/* Annual CTC */}
          <div className="flex items-center">
            <div className="w-1/2">  
              <label
                htmlFor="annual-ctc"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Annual CTC <MandatorySign />
              </label>
              <input
                type="number"
                name="annualCTC"
                id="annual-ctc"
                value={salaryStructure.annualCTC}
                onChange={onChangeAnnualCTC}
                onBlur={onBlurGetSalaryStructure}
                className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Annual CTC"
              />
            </div>
            <div className="w-1/2 ml-6">  
              <label
                htmlFor="regime-type"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Regime Type <MandatorySign />
              </label>
              <Dropdown
                value={salaryStructure.regimeType}
                onChange={onChangeRegimeType}
                onBlur={onBlurGetSalaryStructure}
                options={regimeTypeOptions}
                optionLabel="name"
                placeholder="Select regime type"
                filter
                className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 w-full p-[0.1rem] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 shadow-none"
              />
            </div>
          </div>
          <p className="text-xs mt-1">{numberInWords(salaryStructure.annualCTC)}</p>

          {/* New Changes */}
          <div className="container mx-auto mt-6 shadow-md relative">
            <Table tableClass={"w-full border-collapse table-fixed"}>
              <TableHeader
                cols={salaryComponentsTableHeading}
                styles={{
                  tableHead: "sticky top-0 z-10",
                  tableRow: "",
                  tableCol: `${styles.tc_salary_component_column} !px-2 !pl-2`,
                  headerText: ``,
                }}
              />
            </Table>
            {/* Basic, HRA and Fixed allowance section */}
            <div className="overflow-y-auto ">
              <Table tableClass={"w-full border-collapse table-fixed"}>
                <tbody>
                  <TableRows>
                    <TableData
                      otherProps={{ colSpan: "4" }}
                      className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                      Earnings
                    </TableData>
                  </TableRows>
                  {salaryStructure.earningAssociationValues.map((erAssociationVal, index) => (
                    <TableRows>
                      {/* column-1 Salary Components */}
                      <TableData className={styles.tc_salary_component_column}>
                        {erAssociationVal?.tenantEarningName}
                      </TableData>

                      {/* column-2 Calculation Type */}
                      {erAssociationVal?.calculationType === CalculationType.PercentageOfCTC && (
                        <TableData
                          className={`${styles.tc_salary_component_column} flex items-center`}>
                          <InputFields
                            InputField={{
                              id: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              label: "",
                              type: "number",
                              name: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              placeholder: "",
                              required: false,
                            }}
                            value={erAssociationVal?.calculationValue}
                            onChange={handleEarningValueChange.bind(null, erAssociationVal?.tenantEarningId)}
                            inputClassName="w-16 p-1 border border-gray-300 rounded mr-2"
                            otherProps={{ onBlur: onBlurGetSalaryStructure }}
                          />
                          % of CTC
                        </TableData>
                      )}

                      {erAssociationVal?.calculationType === CalculationType.PercentageOfGross && (
                        <TableData
                          className={`${styles.tc_salary_component_column} flex items-center`}>
                          <InputFields
                            InputField={{
                              id: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              label: "",
                              type: "number",
                              name: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              placeholder: "",
                              required: false,
                            }}
                            value={erAssociationVal?.calculationValue}
                            onChange={handleEarningValueChange.bind(null, erAssociationVal?.tenantEarningId)}
                            inputClassName="w-16 p-1 border border-gray-300 rounded mr-2"
                            otherProps={{ onBlur: onBlurGetSalaryStructure }}
                          />
                          % of Gross
                        </TableData>
                      )}

                      {erAssociationVal?.calculationType === CalculationType.PercentageOfBasic && (
                        <TableData
                          className={`${styles.tc_salary_component_column} flex items-center`}>
                          <InputFields
                            InputField={{
                              id: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              label: "",
                              type: "number",
                              name: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              placeholder: "",
                              required: false,
                            }}
                            onChange={handleEarningValueChange.bind(null, erAssociationVal?.tenantEarningId)}
                            value={erAssociationVal?.calculationValue}
                            inputClassName="w-16 p-1 border border-gray-300 rounded mr-2"
                            otherProps={{ onBlur: onBlurGetSalaryStructure }}
                          />
                          % of Basic
                        </TableData>
                      )}

                      {erAssociationVal?.calculationType === CalculationType.FlatAmount && (
                        <TableData className={styles.tc_salary_component_column}>
                          {calculationTypeLabels[erAssociationVal?.calculationType]}
                        </TableData>
                      )}

                      {/* Column-3 Monthly Amount */}
                      {/* Non-editable if 'Fixed Allowance' or percentage of basic/gross */}
                      {(erAssociationVal.earningCategory === EarningCategory.FixedAllowance || erAssociationVal.calculationType !== CalculationType.FlatAmount) && (
                        <TableData
                          className={styles.tc_salary_component_column}
                          otherProps={{
                            style: { color: formatNumber(erAssociationVal?.monthlyAmount ?? 0) < 0 ? "red" : "black" },
                          }}>
                          ₹{formatNumber(erAssociationVal?.monthlyAmount)}
                        </TableData>
                      )}

                      {/* For flat amounts that are not 'Fixed Allowance' type */}
                      {erAssociationVal.earningCategory !== EarningCategory.FixedAllowance && erAssociationVal.calculationType === CalculationType.FlatAmount && (
                        <TableData className={`${styles.tc_salary_component_column} flex items-center`}>
                          ₹&nbsp;<InputFields
                            InputField={{
                              id: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              label: "",
                              type: "number",
                              name: `calculation-value-${erAssociationVal?.tenantEarningId}`,
                              placeholder: "",
                              required: false,
                            }}
                            value={erAssociationVal?.monthlyAmount}
                            onChange={handleEarningValueChange.bind(null, erAssociationVal?.tenantEarningId)}
                            inputClassName="w-16 p-1 border border-gray-300 rounded mr-2"
                          otherProps={{ onBlur: onBlurGetSalaryStructure }}
                          />
                        </TableData>
                      )}

                      {/* Column-4 Annual Amount */}
                      <TableData
                        className={styles.tc_salary_component_column}
                        otherProps={{
                          style: { color: erAssociationVal?.annualAmount < 0 ? "red" : "black" },
                        }}>
                        ₹{formatNumber(erAssociationVal?.annualAmount)}
                      </TableData>
                    </TableRows>
                  ))}

                  {/* Statutory component section */}
                  {salaryStructure.annualCTC > 0 && (
                    <>
                      <TableRows>
                        <TableData
                          otherProps={{ colSpan: "4" }}
                          className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                          Statutory Components
                        </TableData>
                      </TableRows>

                      {/* EPF Section */}
                      {salaryStructure.salaryStructureResponse.statutoryComponents.some(statutory => statutory.statutoryComponentType === StatutoryComponentType.EPF) && (
                        <>
                          <TableRows>
                            <TableData
                              otherProps={{ colSpan: "4" }}
                              className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                              EPF
                            </TableData>
                          </TableRows>
                          {salaryStructure?.salaryStructureResponse?.statutoryComponents?.filter(statutory => statutory.statutoryComponentType === StatutoryComponentType.EPF)
                            .map((epf, index) => (
                              <>
                                <TableRows>
                                  <TableData className={styles.tc_salary_component_column}>
                                    Employee's Contribution
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    {epf.employeeContributionRate * 100}% of PF Wage
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    ₹{formatNumber(epf?.employeeContribution ?? 0)}
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    ₹{formatNumber(epf?.employeeContributionAnnual ?? 0)}
                                  </TableData>
                                </TableRows>
                                <TableRows>
                                  <TableData className={styles.tc_salary_component_column}>
                                    Employer's Contribution
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    {epf.employerContributionRate * 100}% of PF Wage
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    ₹{formatNumber(epf?.employerContribution ?? 0)}
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    ₹{formatNumber(epf?.employerContributionAnnual ?? 0)}
                                  </TableData>
                                </TableRows>
                              </>
                            ))}
                        </>
                      )}

                      {/* ESI Section */}
                      {salaryStructure?.salaryStructureResponse?.statutoryComponents?.some(statutory => statutory.statutoryComponentType === StatutoryComponentType.ESI) && (
                        <>
                          <TableRows>
                            <TableData
                              otherProps={{ colSpan: "4" }}
                              className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                              ESI
                            </TableData>
                          </TableRows>
                          {salaryStructure.salaryStructureResponse.statutoryComponents
                            .filter(statutory => statutory.statutoryComponentType === StatutoryComponentType.ESI)
                            .map((esi, index) => (
                              <>
                                <TableRows>
                                  <TableData className={styles.tc_salary_component_column}>
                                    Employee's Contribution
                                  </TableData>
                                  <TableData className={styles.tc_salary_component_column}>
                                    {esi.employeeContributionRate * 100}% of Gross Pay
                                  </TableData>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    ₹{formatNumber(esi?.employeeContribution ?? 0)}
                                  </TableData>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    ₹{formatNumber(esi?.employeeContributionAnnual ?? 0)}
                                  </TableData>
                                </TableRows>
                                <TableRows>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    Employer's Contribution
                                  </TableData>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    {esi.employerContributionRate * 100}% of Gross Pay
                                  </TableData>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    ₹{formatNumber(esi?.employerContribution ?? 0)}
                                  </TableData>
                                  <TableData
                                    className={`${styles.tc_salary_component_column}`}>
                                    ₹{formatNumber(esi?.employerContributionAnnual ?? 0)}
                                  </TableData>
                                </TableRows>
                              </>
                            ))}
                        </>
                      )}

                      {/* Professional Tax Section */}
                      {salaryStructure.salaryStructureResponse.statutoryComponents.some(
                        statutory => statutory.statutoryComponentType === StatutoryComponentType.ProfessionalTax
                      ) && (
                          <>
                            <TableRows>
                              <TableData
                                otherProps={{ colSpan: "4" }}
                                className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                                Professional Tax
                              </TableData>
                            </TableRows>
                            {salaryStructure.salaryStructureResponse.statutoryComponents
                              .filter(statutory => statutory.statutoryComponentType === StatutoryComponentType.ProfessionalTax)
                              .map((pt, index) => (
                                <>
                                  <TableRows>
                                    <TableData
                                      className={`${styles.tc_salary_component_column}`}
                                      otherProps={{
                                        colSpan: "2",
                                      }}>
                                      Professional Tax
                                    </TableData>
                                    <TableData
                                      className={`${styles.tc_salary_component_column}`}>
                                      {formatNumber(pt?.employeeContribution ?? 0)}
                                    </TableData>
                                    <TableData
                                      className={`${styles.tc_salary_component_column}`}>
                                      {formatNumber(pt?.employeeContributionAnnual ?? 0)}
                                    </TableData>
                                  </TableRows>
                                </>
                              ))}
                          </>
                        )}
                    </>
                  )}
                  {/* Pre-tax deductions */}
                  {salaryStructure.tenantPreTaxDeductions.length > 0 && (
                  <TableRows>
                    <TableData
                      otherProps={{ colSpan: "4" }}
                      className={"p-2 pl-2 font-semibold text-base bg-gray-100"}>
                        <div className="flex">
                          <span>Pre-Tax Deductions</span>
                          <div className="tc-dropdown tc-dropdown-svg">
                            <SelectWithDropdown
                              inline={true}
                              roundedDot={false}
                              className="-pl-4 font-[500] text-blue-600"
                              size="sm"
                              id="deduction"
                              dotClassName=""
                              options={getPreTaxDeductionOptions()}
                              defaultValue={<AiFillPlusCircle size={'1.2rem'} className='relative left-2 top-1 cursor-pointer bg-black text-white rounded-full h-4 w-4'/>}
                              handleChange={handlePreTaxDeductionOptionSelect}
                              isDefaultValue={true}
                            />
                          </div>
                        </div>
                    </TableData>
                  </TableRows>
                  )}
                  {salaryStructure.deductionAssociationValues && salaryStructure.deductionAssociationValues.map((deductionAssociation, index) => (
                    <TableRows>
                      <TableData className={styles.tc_salary_component_column}>
                        {deductionAssociation.tenantDeductionName}
                      </TableData>
                      <TableData className={styles.tc_salary_component_column}>
                        Flat Amount
                      </TableData>
                      <TableData className={`${styles.tc_salary_component_column} flex items-center`}>
                        ₹&nbsp;<InputFields
                          InputField={{
                            id: `calculation-value-${deductionAssociation?.tenantDeductionId}`,
                            label: "",
                            type: "number",
                            name: `calculation-value-${deductionAssociation?.tenantDeductionId}`,
                            placeholder: "",
                            required: false,
                          }}
                          value={deductionAssociation.calculationValue}
                          onChange={handleDeductionValueChange.bind(null, deductionAssociation?.tenantDeductionId)}
                          inputClassName="w-16 p-1 border border-gray-300 rounded mr-2"
                        otherProps={{ onBlur: onBlurGetSalaryStructure }}
                        />
                      </TableData>
                      <TableData className={styles.tc_salary_component_column}>
                        ₹{formatNumber(deductionAssociation.calculationValue * 12)}
                        <button
                          className="text-lg font-medium pt-2 text-red-500 ml-4"
                          onClick={(event) => handleDeleteSelectedPreTaxDeduction(event, deductionAssociation.tenantDeductionId, deductionAssociation.tenantDeductionName)}>
                          <RiDeleteBin6Line size={15} />
                        </button>
                      </TableData>
                    </TableRows>
                  ))}
                </tbody>
              </Table>
            </div>


            {/* Gross earnings and CTC*/}
            <div className="mt-2 w-full bg-[#e8ebf7] ">
              <Table tableClass={"w-full border-collapse table-fixed"}>
                <tbody>
                  <TableRows className="h-7">
                    <TableData
                      colSpan={2}
                      className={`${styles.tc_salary_component_column} p-2 pl-2 font-medium text-base break-words whitespace-normal`}>
                      Income Tax
                    </TableData>
                    <TableData className="p-2 pl-1 font-small text-base break-words whitespace-normal">
                      TDS Amount
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{formatNumber(salaryStructure?.salaryStructureResponse?.monthlyTDS)}
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{formatNumber(salaryStructure?.salaryStructureResponse?.annualTDS)}
                    </TableData>
                  </TableRows>
                  <TableRows className="h-7">
                    <TableData
                      colSpan={2}
                      className={`${styles.tc_salary_component_column} p-2 pl-2 font-medium text-base break-words whitespace-normal`}>
                      Gross Earning
                    </TableData>
                    <TableData className="p-2 pl-1 font-small text-base break-words whitespace-normal">
                      CTC - Emplr Contrib
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{salaryStructure.salaryStructureResponse.monthlyGross.toFixed(2)}
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{salaryStructure.salaryStructureResponse.annualGross.toFixed(2)}
                    </TableData>
                  </TableRows>
                  <TableRows className="h-7">
                    <TableData
                      colSpan={2}
                      className={`${styles.tc_salary_component_column} p-2 font-medium text-base break-words whitespace-normal`}>
                      Cost to Company
                    </TableData>
                    <TableData className="p-2 pl-1 font-small text-base break-words whitespace-normal">
                      Total CTC
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{formatNumber(salaryStructure.salaryStructureResponse.monthlyCTC)}
                    </TableData>
                    <TableData className="p-2 pl-1 font-medium text-base break-words whitespace-normal">
                      ₹{formatNumber(salaryStructure.salaryStructureResponse.annualCTC)}
                    </TableData>
                  </TableRows>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalaryStructure;
