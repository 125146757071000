import { RegimeType } from "../enum/RegimeType";
import { Role } from "../enum/Role";

export interface UserDto
{
    id: number,
    reportingManagerId: number, 
    reportingManager: any,
    
    //Basic details
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string, 
    dateOfBirth: string, 
    address: string,
    personalEmail: string,
    emergencyContact: string, 
    experience: number, 
    totalExperience?: number, 

    //Employment details
    employeeNumber: string, 
    designation: string, 
    department: string, 
    dateOfJoining: string, 
    dateOfConfirmation: string,
    workLocation: string,
    esiNumber: string, 
    pfNumber: string,
    uan: string,

    //Bank details
    pan: string, 
    bankAccountNumber: string, 
    bankName: string,
    accountHolderName: string, 
    ifscCode: string,
    
    roleId: number,

    //Miscellaneous
    status: number, 
    profileImage: string, 
    imageBytes: string,

    //Salary details
    annualCTC: number,
    regimeType: number,
    earningAssociations: any[]; 
    deductionAssociations: any[]; 
    
    // custom user field associations - currently used for employment type only
    userFieldValues: any[]; 
}

export const newUser: UserDto = {
    id: 0,
    reportingManagerId: 0,
    reportingManager: null,
    
    //Basic details
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '', 
    dateOfBirth: '', 
    address: '',
    personalEmail: '',
    emergencyContact: '', 
    experience: 0,
  
    //Employment details
    employeeNumber: '', 
    designation: '', 
    department: '', 
    dateOfJoining: '', 
    dateOfConfirmation: '',
    workLocation: '',
    esiNumber: '', 
    pfNumber: '',
    uan: '',
  
    //Bank details
    pan: '', 
    bankAccountNumber: '', 
    bankName: '',
    accountHolderName: '', 
    ifscCode: '',
    
    roleId: Role.Member,
  
    //Miscellaneous
    status: 0, 
    profileImage: '', 
    imageBytes: '',
  
    //Salary details
    annualCTC: 0,
    regimeType: RegimeType.New,
    earningAssociations: [], 
    deductionAssociations: [], 
    
    // custom user field associations
    userFieldValues: [], 
  };
  