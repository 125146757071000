import { useRef, useState } from "react";
import { useEffect } from "react";
import { Pagination, Tabs } from "flowbite-react";
import { HiSearch } from "react-icons/hi";

import PageSection from "../../Layout/PageSection/PageSection";
import { EmployeeManagementButtons } from "../AddEmployee";
import EmployeeListTableHeading from "../TableHeading";
import EmployeesList from "../EmployeesList";

import { GetUsers } from "../../../services/UserService";
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO } from "../../../constants/Constant";
import { setPageNo } from "../../../store/slices/pagination";
import EmployeeFilter from "../EmployeeFilter/EmployeeFilter";
import { UserSvg } from "../../../media/UserSvg";
import usePagination, { PaginationComponent } from "../../../hooks/usePagination";
import { UserStatus } from "../../../enum/UserStatus";
import { userManagementTabs } from "../helper";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setAllUsers, setSelectedEmployeeListTab } from "../../../store/slices/userManagementSlice";

/**
 * Create and manage employees.
 *
 * @return {JSX.Element} The component JSX element.
 */

const ManageEmployees = () => {
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement)

  // Setting the page no
  const { pageSize, pageNo, totalPages } = useAppSelector(state => state.pagination);

  // Setting the page size
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  // const [isEmpFilterOpen, setIsEmpFilterOpen] = useState(false);

  // sorting params
  const [activeSortColumn, setActiveSortColumn] = useState(1);
  const [ascendingOrder, setAscendingOrder] = useState(1);

  const { setPageSizeHandler, onPageChange } = usePagination(false, umState.allUsers.totalRecords);

  const tabsRef = useRef(null);

  //State to select all checkbox
  // const [selectAllRestrictedUsersCheckbox, setSelectAllRestrictedUsersCheckbox] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (umState.allUsers.users.length <= 0) return;
      if (searchQuery.length <= 0) return setFilteredList([]);

      const filteredList = umState.allUsers.users.filter(user =>
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredList(filteredList);
    }, 200);

    return () => clearTimeout(timeout);
  }, [searchQuery]);

  const fetchUsers = (pageSize, pageNo, userStatus) => {
    GetUsers(
      pageSize,
      pageNo,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      userStatus
    ).then(response => {
      dispatch(setAllUsers({
        users: response.data,
        records: response.records,
        totalRecords: response.totalRecords,
      }))
    });
  };

  useEffect(() => {
    dispatch(setPageNo(1));
  }, [umState.selectedEmployeeListTab]);

  useEffect(() => {
    if (umState.selectedEmployeeListTab === userManagementTabs.active) {
      fetchUsers(pageSize, pageNo, UserStatus.Active);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.pendingApproval) {
      fetchUsers(pageSize, pageNo, UserStatus.PendingEmailVerification);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.inActive) {
      fetchUsers(pageSize, pageNo, UserStatus.Deactivated);
    } else if (umState.selectedEmployeeListTab === userManagementTabs.restrictedLogin) {
      fetchUsers(pageSize, pageNo, UserStatus.RestrictedLogin);
    }
  }, [pageSize, pageNo, umState.refetchUsers, umState.selectedEmployeeListTab]);

  const onChangeHandler = e => {
    const { value } = e.currentTarget;
    setSearchQuery(value);
  };

  // sort table data
  function sortTableData(updatedOrder, selectedColumn) {
    setActiveSortColumn(selectedColumn);
    setAscendingOrder(updatedOrder);
    GetUsers(pageSize, pageNo, selectedColumn, updatedOrder).then(response => {
      dispatch(setAllUsers({
        users: response.data,
        records: response.records,
        totalRecords: response.totalRecords
      }));
    });
  }

  //Function to handle tabs
  const handleTabChange = tab => {
    dispatch(setSelectedEmployeeListTab(tab));
  };

  //Function to select all users checkbox from restricted users
  // const toggleSAelectAll = () => {
  //   const updatedUsers = data?.users?.map(user => ({
  //     ...user,
  //     selected: !selectAllRestrictedUsersCheckbox,
  //   }));

  //   setData({ ...data, users: updatedUsers });
  //   setSelectAllRestrictedUsersCheckbox(!selectAllRestrictedUsersCheckbox);
  // };

  // Function to select individual users checkbox from restricted users
  // const toggleSelectedUserCheckboxChange = userId => {
  //   const updatedUsers = data?.users?.map(user => {
  //     if (user.id === userId) {
  //       return {
  //         ...user,
  //         selected: !user.selected,
  //       };
  //     }
  //     return user;
  //   });
  //   setData({ ...data, users: updatedUsers });
  //   const allSelected = updatedUsers.every(user => user.selected);
  //   setSelectAllRestrictedUsersCheckbox(allSelected);
  // };

  return (
    <PageSection
      heading="User Management"
      icon={<UserSvg _size="w-[2rem] h-[2rem]" />}
      showIcon={true}
      containerClass="bg-white rounded-[8px]"
      button={
        <EmployeeManagementButtons />
      }>
      <div className="px-4 divide-y dark:divide-gray-700">
        <div className="flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4">
          <div className="flex items-center justify-between flex-1 space-x-4">
            {/* tabs */}
            <div className="relative text-gray-900 dark:text-white tab-payslip-links">
              <Tabs.Group
                aria-label="Default tabs"
                ref={tabsRef}
                onActiveTabChange={handleTabChange}>
                <Tabs.Item active title="Active"></Tabs.Item>
                <Tabs.Item title="Pending Verification"></Tabs.Item>
                <Tabs.Item title="Inactive"></Tabs.Item>
                {/* <Tabs.Item title="Restricted Login"></Tabs.Item> */}
              </Tabs.Group>
            </div>
            <div className="flex items-center gap-3 relative">
              {/* {activeTab === userManagementTabs.restrictedLogin && (
                <button className="font-semibold" onClick={onSendVerificationEmail}>
                  Send Verification Email
                </button>
              )} */}

              <div className="relative">
                <input
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pr-3 shadow-sm focus:outline-none focus:border-thynkwebPrimary-500 focus:ring-thynkwebPrimary-500 focus:ring-1 dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 sm:text-sm"
                  placeholder="Search"
                  type="text"
                  name="search"
                  value={searchQuery}
                  onChange={onChangeHandler}
                />
                <div className="absolute pr-3 inset-y-0 right-0 flex items-center">
                  <HiSearch className="fill-thynkwebPrimary-500 z-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto pb-3 bg-white">
        <table className="w-full text-sm text-left border-collapse rounded-none">
          <EmployeeListTableHeading
            activeSortColumn={activeSortColumn}
            ascendingOrder={ascendingOrder}
            sortTableData={sortTableData}
            // toggleSAelectAll={toggleSAelectAll}
            // selectAllRestrictedUsersCheckbox={selectAllRestrictedUsersCheckbox}
          />
          <tbody>
            <EmployeesList
              users={filteredList.length === 0 ? umState.allUsers.users : filteredList}
              // toggleSelectedUserCheckboxChange={toggleSelectedUserCheckboxChange}
            />
          </tbody>
        </table>
        <h5 className="pl-4 pt-2">
          <span className="text-gray-500">Total Users: </span>
          <span className="dark:text-white">{umState.allUsers?.totalRecords}</span>
        </h5>
      </div>

      <PaginationComponent
        onChange={setPageSizeHandler}
        onPageChange={onPageChange}
        showIcons={true}
      />
    </PageSection>
  );
};

export default ManageEmployees;
