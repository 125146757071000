import {
  AddBusinessMessages,
  AddNewLeaveMessages,
  AnnouncementMessages,
  AttendanceMessages,
  AttendanceSettingsMessages,
  AttendanceValidateFields,
  EmployeeListMessages,
  EmployeeMessages,
  FileMessages,
  InviteUserMessages,
  JobMessages,
  LeaveTypesMessages,
  LoginMessages,
  LogoMessages,
  OrganizationManagementMessages,
  ProfileMessages,
  ProjectMessages,
  QuickLinkMessages,
  ResetPasswordMessages,
  RolesMessages,
  VerifyUserMessages,
  StatutoryMessage,
  SalaryMessages,
} from "../models/Messages";

export const ORGANIZATION_MANAGEMENT: OrganizationManagementMessages = {
  SUCCESS: "Organization created successfully",
  FAIL: "Organization creation failed",
};

export const ROLES_MESSAGES: RolesMessages = {
  SUCCESS: "Role created successfully",
  UPDATE: "Role updated successfully",
  UPDATE_FAILED: "Role update failed",
  SUCCESS_FAILED: "Role created failed",
};

export const ADD_BUSINESS_MESSAGES: AddBusinessMessages = {
  SUCCESS: "added successfully",
  UPDATE: "updated successfully",
  LOGO_CHANGES_NOT_SAVED: "Newly added logo is not saved yet. Please save the changes.",
  LOGO_UPLOAD_FAILED:
    "Something went wrong, While trying to upload the logo. Please Edit the business and try again!",
  LOGO_DELETE: "Logo deleted successfully",
  REMOVE_UNSAVED_LOGO: "Removed unsaved logo.",
};

export const FILE_MESSAGES: FileMessages = {
  BUSINESS_LOGO_SIZE:
    "Please select an image that is 1080x1080px or smaller and up to 5MB in size.",
  FILE_TYPE_ERROR: "Please select a PNG or JPEG/JPG file.",
};

export const PROJECT_MESSAGES: ProjectMessages = {
  SUCCESS: "Project created successfully",
  UPDATE: "Project updated successfully",
  UPDATE_FAILED: "Project update failed",
  SUCCESS_FAILED: "Project created failed",
  DELETE: "Project deleted successfully",
  MANDATORY_FIELDS: "Please fill the mandatory fields !",
};

export const JOB_MESSAGES: JobMessages = {
  SUCCESS: "Job created successfully",
  UPDATE: "Job updated successfully",
  UPDATE_FAILED: "Job update failed",
  SUCCESS_FAILED: "Job created failed",
  DELETE: "Job deleted successfully",
  MANDATORY_FIELDS: "Please fill the mandatory fields !",
  RESPONSE_ERROR: "Some error occured. Try again later !",
};

export const QUICKLINK_MESSAGES: QuickLinkMessages = {
  SUCCESS: "Quick Link added successfully",
  WARN: "All fields are mandatory!",
  UPDATE: "Quick Link updated successfully",
  UPDATE_FAILED: "Quick Link update failed",
  DELETE: "Quick Link deleted successfully",
  RESPONSE_ERROR: "Some error occured. Try again later !",
  LOADING_ADD: "Adding Quick Link...",
  LOADING_DELETE: "Deleting Quick Link...",
};

export const ATTENDANCE_SETTINGS_MESSAGES: AttendanceSettingsMessages = {
  SUCCESS: "Settings updated successfully",
  FAIL: "Error while updating attendance",
};

export const LEAVE_TYPES_MESSAGE: LeaveTypesMessages = {
  SUCCESS: "Leave type created successfully",
  UPDATE: "Leave type updated successfully",
  UPDATE_FAILED: "Leave type update failed",
  SUCCESS_FAILED: "Leave type created failed",
  DELETE: "Leave type deleted successfully",
  MANDATORY_FIELDS: "Please fill the mandatory fields !",
  RESPONSE_ERROR: "Some error occured. Try again later !",
  LOADING_ADD: "Adding Leave type...",
  LOADING_DELETE: "Deleting Leave type...",
  SHORT_CODE_ERROR: "Leave type short code already exists. Please enter a unique short code.",
};

export const ANNOUCEMENT_MESSAGES: AnnouncementMessages = {
  SUCCESS: "Announcement added successfully",
  WARN: "All fields are mandatory!",
  UPDATE: "Announcement updated successfully",
  UPDATE_FAILED: "Announcement update failed",
  DELETE: "Announcement deleted successfully",
  RESPONSE_ERROR: "Some error occured. Try again later !",
  LOADING_ADD: "Adding Announcement...",
  LOADING_DELETE: "Deleting Announcement...",
  LOADING_UPDATE: "Updating Announcement...",
};

export const PROFILE_MESSAGES: ProfileMessages = {
  CHECK_IN: "Successfully checked in",
  CHECK_OUT: "Successfully checked out",
  SELECT_JOB: "Please select a job",
};

export const ATTENDANCE_VALIDATE_FIELDS: AttendanceValidateFields = {
  SELECT_USER: "Please select a user",
  START_DATE: "Please select start date",
  END_DATE: "Please select end date",
};

export const EMPLOYEE_LIST_MESSAGES: EmployeeListMessages = {
  RESEND_VERIFICATION_EMAIL_SUCCESS: "Email re-sent successfully",
  RESEND_VERIFICATION_EMAIL_FAILED: "Failed to re-send verification email",
  SEND_VERIFICATION_EMAIL_SUCCESS: "Verification email sent successfully",
  SEND_VERIFICATION_EMAIL_FAILED: "Failed to send verification email",
};

export const EMPLOYEE_MESSAGES: EmployeeMessages = {
  SUCCESS: "Employee created successfully",
  UPDATE: "Employee updated successfully",
  UPDATE_FAILED: "Employee update failed",
  SUCCESS_FAILED: "Employee created failed",
  DELETE: "Employee deleted successfully",
  MANDATORY_FIELDS: "Please fill the mandatory fields !",
  RESPONSE_ERROR: "Some error occurred. Try again later !",
  LOADING_ADD: "Adding Employee...",
  LOADING_DELETE: "Deleting Employee...",
  LOADING_UPDATE: "Updating Employee...",
  DEACTIVATE_SUCCESS: "User deactivated successfully",
  DEACTIVATE_ERROR: "User deactivation failed",
  REACTIVATE_SUCCESS: "User re-activated successfully",
  REACTIVATE_ERROR: "User re-activation failed",
};

export const LOGIN_MESSAGES: LoginMessages = {
  EMAIL_FIELDS: "Please enter your email",
  PASSWORD_FIELDS: "Please enter your password",
  VALID_EMAIL: "Please enter a valid email address",
  INVALID_CREDENTIALS: "Invalid credentials",
};

export const LOGO_MESSAGE: LogoMessages = {
  SUCCESS: "Logo uploaded successfully",
  UPDATE: "Logo updated successfully",
  UPDATE_FAILED: "Logo update failed",
  SUCCESS_FAILED: "Logo upload failed",
  DELETE: "Logo deleted successfully",
  FORMAT_ERROR: "Invalid file format. Please select a .gif, .jpeg, .png or .jpg file.",
  ERROR: "Logo is not present, Please select a logo first!",
  HEADER_FONT_AND_BACKGROUND_COLOR_SUCCESS: "Header font and background color updated successfully",
  HEADER_FONT_AND_BACKGROUND_COLOR_FAILED: "Header font and background color update failed",
};

export const RESET_PASSWORD_MESSAGES: ResetPasswordMessages = {
  SUCCESS: "Password changed successfully",
  FAIL: "Error while changing password",
  EXPIRED_TOKEN: "Token expired.",
};

export const VERIFY_USER_MESSAGES: VerifyUserMessages = {
  SUCCESS: "User verified successfully",
  FAIL: "Error while verifying user",
  EXPIRED_TOKEN: "Token expired.",
  PASSWORD_ERROR: "Please enter your password",
};

export const ATTENDANCE_MESSAGES: AttendanceMessages = {
  TIMELOG_DELETE: "Time log deleted successfully",
  ATTENDANCE_ADD: "Time log added successfully",
  SELECT_JOB: "Please select a job",
  WORK_DESCRIPTION: "Please fill your work description",
  TIMER_START: "Timer started successfully",
  LOCATION_PERMISSION: "Please enable your location",
  TIMER_STOP: "Timer stopped successfully",
  TIMER_FAILED: "Failed to start timer. Please try again",
};

export const ADD_NEW_LEAVE_MESSAGES: AddNewLeaveMessages = {
  SUCCESS: "Leave applied successfully",
  UPDATE: "Leave updated successfully",
  UPDATE_FAILED: "Leave update failed",
  SUCCESS_FAILED: "Leave created failed",
  DELETE: "Leave deleted successfully",
  MANDATORY_FIELDS: "Please fill the mandatory fields !",
  END_DATE_ERROR_MESSAGE: "Leave End Date is not correct!",
  EMPTY_DATE_MESSAGE: "Please enter date",
  EMPTY_LEAVE_TYPE_ERROR: "Please enter leave type",
  LEAVE_APPROVED: "Leave Approved!",
  LEAVE_REJECTED: "Leave has been rejected",
};

export const INVITE_USER_MESSAGES: InviteUserMessages = {
  VALID_EMAIL: "Please enter a valid email address",
};

export const STATUTORY_MESSAGE: StatutoryMessage = {
  EPF_SUCCESS: "EPF details updated successfully",
  EPF_SUCCESS_FAILED: "EPF details update failed",
  EPF_DELETE: "EPF details disabled successfully",
  EPF_MANDATORY_FIELDS: "Please fill the mandatory fields !",
  ESI_SUCCESS: "ESI details updated successfully",
  ESI_SUCCESS_FAILED: "ESI details update failed",
  ESI_DELETE: "ESI details disabled successfully",
  ESI_MANDATORY_FIELDS: "Please fill the mandatory fields !",
  PROFESSIONAL_TAX_SUCCESS: "Professional Tax details updated successfully",
  PROFESSIONAL_TAX_SUCCESS_FAILED: "Professional Tax details update failed",
  PROFESSIONAL_TAX_DELETE: "Professional Tax details disabled successfully",
  PROFESSIONAL_TAX_MANDATORY_FIELDS: "Please fill the mandatory fields !",
};

export const DELETE_MESSAGE_SALARY: SalaryMessages = {
  DEL_EARNING: "Earning Deleted successfully",
  DEL_DEDUCTION: " Deduction Deleted successfully",
};
